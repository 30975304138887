@charset "utf-8";
@import 'hukka-bulma-vars';
@import 'node_modules/bulma/bulma';

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-style: normal;
  font-weight: 400;
  color: $text-color;
  margin: 0px;
  padding: 0px;
}

.hideMobile {
  @media screen and (max-width: 699px) {
    display: none;
  }
}
.hideDesktop {
  @media screen and (min-width: 700px) {
    display: none;
  }
}

#app {
  // font-family: $family-sans-serif;
  font-family: 'Work Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $secondary-color;
  padding-bottom: 0;
  margin-bottom: 0;
  min-height: 100vh;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.flashAnimation {
  animation-name: flash;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

button.disabled {
  color: #aaa!important;
}

h1 {
  font-family: 'Bebas Neue';
}
h2 {
  font-family: 'Bebas Neue';
}

.textarea {
  border: 5px solid $primary-color;
  width: 100%;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.title {
  color: $primary-color;
}

.text-primary-color {
  color: $primary-color;
}

.text-secondary-color {
  color: $secondary-color;
}

.text-danger-color {
  color: red;
}

.is-primary-background {
  background: $primary-color;
}

.is-secondary-background {
  background: $secondary-color;
}

.targetValue {
  background: $primary-color;
  color: #fff;
}

.alarmValue {
  background: red;
  color: #fff;
}

.ribbonTitle {
  font-family: 'Bebas Neue';
  background: $primary-color; 
  color: #fff;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  padding: 12px 0 6px 0;
  font-size: 20px;
  @media screen and (min-width: 900px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }
  line-height: 1;
  text-transform: uppercase;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #fff;
    height: 20px;
    width: 20px;
    @media screen and (min-width: 900px) {
      height: 35px;
      width: 35px;
    }
    @media screen and (min-width: 1200px) {
      height: 50px;
      width: 50px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    border-radius: 50%;
    background: #fff;
    height: 20px;
    width: 20px;
    @media screen and (min-width: 900px) {
      height: 35px;
      width: 35px;
    }
    @media screen and (min-width: 1200px) {
      height: 50px;
      width: 50px;
    }
  }
}

.hukkaBtn {
  font-size: 30px;
  line-height: 1;
  text-transform: uppercase;
  padding: 20px 20px;
  background: $secondary-color;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  &--disabled {
    cursor: default;
    background: rgba($secondary-color, 0.7);
  }
}

.removeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $danger;
  font-size: 25px;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: red;
  }
}
.editBtn {
  position: absolute;
  top: 12px;
  right: 36px;
  color: $primary-color;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: $secondary-color;
  }
}

.container {
  padding: 20px 2%;
  @media screen and (min-width: 768px) {
    padding: 60px 2%;
  }
  width: 100%;
  max-width: 1760px;
}
.title .button {
  font-family: 'Bebas Neue';
  font-style: bold;
  font-weight: 700;
}

.button {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.levelRow {
  background-color: white;
  border-width: 2px;
  border-color: #dbdbdb;
  border-style: solid;
  padding: 0px;
}

.levelRow .button {
  text-transform: uppercase;
  font-size: 1rem;
  border-style: none;
  border-left-style: solid;
  border-left-width: 2px;
  border-color: #dbdbdb;
  line-height: 2rem;
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0px;
}

.levelRowTitle {
  padding-left: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.hukkaselect {
  margin-bottom: 0.75rem;
  cursor: pointer;
  height: auto!important;
  min-height: 2.5em!important;
  &>div>div {
    border-width: 5px;
    border-color: $primary-color;
    cursor: pointer;
    &:hover {
      border-color: rgba($primary-color, 0.5);
    }
    &>div:first-of-type {
      padding: 2px 40px 2px 8px;
    }
    svg {
      color: red;
      z-index: 1;
    }
    &>div:last-of-type>div>svg {
      margin-left: -40px;
    }
  }
}

.hukkaselect select {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
}

#hukka-logo {
  margin-top: 3rem;
  width: 100%;
  max-width: 800px;
}
#fullscreen-hukka-logo {
  position: absolute;
  width: 12%;
  bottom: 10px;
  right: 2%;
}

.modal-card-head {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.modal-card-foot {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-card--fullwidth {
  width: 96%!important;
}

/* ### Navigation ### */
.mainNav {
  font-family: 'Bebas Neue';
  background: $secondary-color;
  display: block;
  width: 100%;
  padding: 10px 5% 5px 5%;
  z-index: 100;
  &__items {
    display: none;
    margin-top: 50px;
    @media screen and (min-width: 1000px) {
      width: 100%;
      margin: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
    @media screen and (max-width: 999px) {
      &--open {
        display: block;
      }
    }
  }
  &__item {
    width: 100%;
    display: block;
    margin: 16px 0;
    @media screen and (min-width: 1000px) {
      width: auto;
      display: inline-block;
      margin: 0;
    }
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    @media screen and (min-width: 1400px) {
      font-size: 30px;
      line-height: 30px;
    }
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
  &__select {
    cursor: pointer;
    z-index: 100;
    position: relative;
    &>.mainNav__item {
      &:after {
        content: '\25BC';
      }
    }
    &__options {
      z-index: 100;
      display: none;
      &__item {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0 30px;
      }
    }
    &:hover {
      &>.mainNav__item {
        color: $primary-color;
      }
      .mainNav__select__options {
        display: inline-block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        background: $secondary-color;
      }
    }
  }
  &__burger {
    display: block;
    margin: 10px 0 20px 0;
    @media screen and (min-width: 1000px) {
      display: none;
    }
    &__link {
      display: block;
      height: 30px;
      position: relative;
      width: 40px;
      margin: auto;
      span {
        position: absolute;
        background-color: #fff;
        height: 6px;
        margin-top: 8px;
        margin-left: 0px;
        width: 100%;
        &:nth-child(1) {
          transform: translate(-50%, 0);
          top: 4px;
          margin-top: 0px;
          margin-left: 0px;
        }
        
        &:nth-child(2) {
          transform: translate(-50%, 0);
          top: 16px;
          margin-top: 0px;
          margin-left: 0px;
        }
        
        &:nth-child(3) {
          transform: translate(-50%, 0);
          top: 28px;
          margin-top: 0px;
          margin-left: 0px;
        }
      }
    }
  }

  .icon {
    i {
      font-size: 24px;
    }
  }
}



@media only screen and (max-width: 1023px) {
  .navbar-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .levelRow .button {
    margin: 1rem;
    border: solid;
  }
}
.taginput .taginput-container > .tag {
  padding: 1.2em;
  width: 100%;
  font-size: 1.2em;
}

#hukka-content {
  flex: 1;
}

.footer-image {
  width: 150px;
  margin: 0 auto;
}

#hukka-footer div {
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  margin-bottom: -5px;
  height: 150px;
  width: 100px;
}
#hukka-footer div img {
  width: 100%;
  margin: 0px;
}
.is-green {
  background-color: #8ab51d;
  color: #fff;
  font-weight: bold;
}
.is-red {
  background-color: $danger;
  color: #fff;
  font-weight: bold;
}

.is-green:hover {
  opacity: 0.9;
  color: #fff;
}
.is-red:hover {
  opacity: 0.9;
  color: #fff;
}

.is-green:disabled {
  background-color: #8ab51d;
  opacity: 0.5;
  color: #2c3e50;
}
.is-red:disabled {
  background-color: $danger;
  opacity: 0.5;
  color: #2c3e50;
}
.is-input-middle {
  text-align: center;
}

.old_entry {
  color: rgba(0, 0, 0, 0.3);
}

.red-border {
  border: 2px solid $danger;
}



/* Lander */
.insight {
  vertical-align: top;
  display: inline-block;
  margin: 20px 0;
  height: 200px;
  width: 100%;
  max-width: 300px;
  background: $primary-color;
  border-radius: 10%;
  position: relative;

  @media screen and (min-width: 500px) {
    margin: 20px;
  }

  &__icon {
    position: absolute;
    color: $secondary-color;
    top: 10px;
    left: 10px;
    width: 50px;
  }

  &__text {
    color: $secondary-color;
    position: relative;
    font-weight: 700;
    margin-top: 75px;
    top: 0;
    width: 90%;
    left: 5%;
  }

  button {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__number {
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    height: 100px;
    width: 100px;

    span {
      font-weight: 700;
      font-size: 22px;
      color: #000;
      width: 100%;
      position: absolute;
      left: 45%;
      top: 50%;
      transform: translate(-50%, -20%);
    }

    &--red {
      span {
        color: $danger;
      }
    }

    &--green {
      span {
        color: $primary-color;
      }
    }
  }
}


/* Insights */
.insightlist {
  position: relative;
  display: inline-block;
  width: 90%;
  max-width: 400px;
  margin: 10px;
  border: 3px solid #000;
  border-radius: 10px;
  vertical-align: top;

  ul {
    li {
      padding: 5px;
    }
  }
}

/* Restaurants */
.meal-types {
  display: block;
  margin: auto;
  width: 100%;
  &__item {
    text-align: left;
    height: 50px;
    color: $text-color;
    font-size: 20px;
    .button {
      float: right;
    }
  }
}

/* Plate waste */
.carouselButtons {
  width: 100%;
  display: block;
  position: relative;
  .button {
    display: inline-block;
    margin: auto;
    width: 100px;
  }
}
.carousel {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  &__wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  &__item {
    position: absolute;
    overflow: hidden auto;
    padding: 3% 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  p {
    font-family: 'Bebas Neue';
  }
}
.platewasteBg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: auto;
  &__img {
    position: relative; 
    height: 100%; 
    width: auto;
    max-width: none!important;
    display: block; 
    margin: auto;
  }
}
.platewasteWebM {
  position: absolute;
  top: 20%;
  left: 0;
  height: 80%;
  width: 80%;
  z-index: 10;
}

.platewasteMenu {
  display: block;
  height: calc(96% - 58px);
  display: grid;
  width: 100%;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 50% 50%;
  align-items: center;
  justify-content: center;
  &__item {
    background: $secondary-color;
    overflow: hidden;
    color: #fff;
    margin: 2%;
    border: 4px solid $primary-color;
    height: 92%;
    position: relative;
    @media screen and (min-width: 900px) {
      border: 6px solid $primary-color;
    }
    @media screen and (min-width: 1200px) {
      border: 8px solid $primary-color;
    }
    .ribbonTitle {
      font-family: 'Bebas Neue';
      left: -60px;
      width: calc(80% + 60px);
      text-align: left;
      padding: 0;
      text-indent: 65px;
      font-size: 16px;
      height: 20px;
      @media screen and (min-width: 900px) {
        height: 25px;
        font-size: 20px;
      }
      @media screen and (min-width: 1200px) {
        height: 35px;
        font-size: 30px;
      }
      &:before {
        background: $secondary-color;
      }
      &:after {
        background: $secondary-color;
      }
    }
    &__foods {
      padding: 10px;
      position: absolute;
      top: calc(50% + 15px);
      @media screen and (min-width: 900px) {
        top: calc(50% + 20px);
      }
      @media screen and (min-width: 1200px) {
        top: calc(50% + 30px);
      }
      left: 0;
      width: 100%;
      transform: translate(0, -50%);
      font-size: 1.2vw;
    }
  }
  .weekBox {
    background: $primary-color;
    position: relative;
    overflow: visible;
    font-family: 'Bebas Neue';
    &__image {
      position: absolute;
      top: -100%;
      left: 0;
      width: 100%;
      height: calc(200% + 8px);
      overflow: hidden;
      img {
        max-width: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-55%, -70%);
        height: 100%;
        width: auto;
      }
    }
    &__menutext {
      position: absolute;
      bottom: -9px;
      right: -4px;
      line-height: 30px;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      @media screen and (min-width: 900px) {
        bottom: -15px;
        right: -6px;
        line-height: 50px;
        font-size: 50px;
      }
      @media screen and (min-width: 1200px) {
        bottom: -22px;
        right: -10px;
        line-height: 70px;
        font-size: 70px;
      }
    }
    &__weektext {
      position: absolute;
      bottom: 18px;
      right: -4px;
      font-size: 20px;
      line-height: 20px;
      text-align: right;
      text-transform: uppercase;
      @media screen and (min-width: 900px) {
        bottom: 26px;
        right: -8px;
        font-size: 40px;
        line-height: 40px;
      }
      @media screen and (min-width: 1200px) {
        bottom: 34px;
        right: -10px;
        font-size: 60px;
        line-height: 60px;
      }
    }
  }
}

@keyframes asd {
  0% {
    margin-top: 100%;
  } 10% {
    margin-top: 0;
  } 50% {
    margin-top: 0;
  } 100% {
    margin-top: 100%;
  }
}

.lastFiveMenu {
  display: block;
  position: relative;
  width: calc(70% - 76px);
  left: calc(50% + 6px);
  transform: translate(-50%, 0px);
  @media screen and (min-width: 900px) {
    width: calc(70% - 72px);
    left: calc(50% + 4px);
  }
  @media screen and (min-width: 1200px) {
    width: calc(70% - 66px);
    left: 50%;
  }
  &__weekDay {
    display: inline-block;
    margin: auto;
    padding: 10px;
    &__date {
      margin: 0;
      padding: 0;
      font-weight: 700;
    }
  }
  &__day {
    display: inline-block;
    width: 20%;
    padding-left: 30px;
    vertical-align: top;
    font-size: 0.7vw;
    line-height: 1.5;
    margin-top: -15px;
    @media screen and (min-width: 900px) {
      margin-top: -10px;
    }
    @media screen and (min-width: 1200px) {
      margin-top: -5px;
    }
  }
}

.lastMonthAvg {
  font-size: 1.2vw;
}

.menu {
  display: block;
  width: 100%;
  &__weekDay {
    display: inline-block;
    margin: auto;
    padding: 10px;
    &__date {
      margin: 0;
      padding: 0;
      font-weight: 700;
    }
  }
}

/* Info */
.videoGrid {
  display: block;
  width: 100%;
  margin: auto;
  margin-bottom: 30px;
  @media screen and (min-width: 500px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 10px;
    align-content: center;
  }
  iframe {
    width: 100%;
  }
}

/* survey */
.survey {
  &__thankyou {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 5%;
    width: 100%;
  }

  &__buttonArea {
    position: relative;
    display: block;
    margin: auto;
    height: 100%;
    width: 100%;
  }

  &__button {
    width: 45%;
    position: absolute;
    top: 50%;
    transform: translate(0, -55%);

    &--like {
      left: 5%;
    }
    &--dontlike {
      right: 5%;
    }
  }
}

/* Menu */
.restaurantGroupsEdit {
  background-color: white;
  border-width: 2px;
  border-color: #dbdbdb;
  border-style: solid;
  padding: 0px;
}

.rightCornerBtn {
  position: absolute;
  top: 6px;
  right: 6px;
}

/* Foods */
.foodsTable {
  padding-top: 20px;
  width: 100%;
  display: block;
  margin: auto;
  max-width: 1200px;
  tbody {
    display: table;
    width: 100%;
    tr {
      width: 100%;
      td {
        padding: 10px 0;
        width: 33%;
        &.priceEditTip {
          font-size: calc(8px + 0.3vw);
        }
        &.foodName {
          font-size: calc(10px + 1vw);
          font-weight: 700;
        }
        &.dashLine {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 90%;
            top: 50%;
            left: 5%;
            border-bottom: 2px dashed $primary-color;
          }
        }
        &.foodPrice {
          font-size: calc(10px + 1vw);
          vertical-align: middle;
          span {
            padding: 5px;
            text-align: center;
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}
.foodEditBtn {
  color: $primary-color;
  font-size: 30px;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 10px;
  transition: 0.3s color;
  &:hover {
    color: $secondary-color;
  }
}

/* Order estimation */
.weekEstimationTable {
  margin: auto;
  @media screen and (max-width: 767px) {
    tr, td {
      display: block;
    }
  }
}

/* Waste report */
.has-note {
  border: 1px solid $danger;
}

/* Tags */
.tagCloud {
  border: 2px solid #000;
  border-radius: 20px;
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  &__close {
    padding-left: 5px;
    color: $danger;
    cursor: pointer;
    transition: color 0.3s ease;
    &:hover {
      color: red;
    }
  }
}

// rangeslider
.range {
  width: 100%;
  max-width: 600px;
  margin: auto;
  display: inline-block;
  background-color: transparent;
  -webkit-appearance: none;
  
  &:focus {
    outline: none;
  }

  /* webkit */
  &::-webkit-slider-runnable-track {
    background: #fff;
    border: 0.2px solid $secondary-color;
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  &::-webkit-slider-thumb {
    margin-top: -5px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &--target::-webkit-slider-runnable-track {
    background: $primary-color;
  }
  &--alarm::-webkit-slider-runnable-track {
    background: $danger;
  }

  /* moz */
  &::-moz-range-track {
    background: $primary-color;
    border: 0.2px solid $secondary-color;
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 50px;
    cursor: pointer;
  }
  &--target::-moz-range-track {
    background: $primary-color;
  }
  &--alarm::-moz-range-track {
    background: $danger;
  }

  /* ms */  
  &::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 5.8px 0;
    color: transparent;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  &::-ms-fill-lower {
    background: #1da883;
    border: 0.2px solid $secondary-color;
    border-radius: 2.6px;
  }
  &::-ms-fill-upper {
    background: $primary-color;
    border: 0.2px solid $secondary-color;
    border-radius: 2.6px;
  }
  &::-ms-thumb {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }

  &--target::-ms-fill-upper {
    background: $primary-color;
  }
  &--alarm::-ms-fill-upper {
    background: $danger;
  }
}

/* Magic Forest */
.magicForest {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: left;

  &__videoWrapper {
    position: relative;
  }

  &__pawn {
    width: 3%;
    position: absolute;
  }

  &__panel {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 1.5vh;
    line-height: 1;
  }

  &__title {
    font-size: 2.6vh!important;
    text-decoration: underline;
    display: block;
    margin-bottom: 1.5%;
  }

  &__panelSection {
    position: relative;
    width: 100%;
    padding: 5%;
  }

  &__miniVideo {
    position: relative;
    width: 110%;
    margin-bottom: -1.5%;
    top: -10%;
    left: -5%;
    max-width: none;
  }
  
  &__chartArea {
    display: inline-block;
    width: 100%;
    position: relative;
    top: 4.5%;
    height: 95%;
  }
  
  &__chartNumbersLeft {
    width: 10%;
    height: 100%;
    position: relative;
    text-align: right;
  }

  &__chartNumbersRight {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    text-align: left;
  }

  &__chartMax {
    position: absolute;
    top: 0;
    right: 0.6vw;
    line-height: 1;
    transform: translate(0, -50%);
  }

  &__chartMin {
    position: absolute;
    bottom: 0;
    right: 0.6vw;
    line-height: 1;
    transform: translate(0, 50%);
  }

  &__avgLine {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed red;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__avgValue {
    position: absolute;
    left: 0.5vw;
    bottom: 0%;
    transform: translate(0, 50%);
  }

  &__chart {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    position: absolute;
    display: inline-block;
    width: 80%;
    left: 10%;
    top: 0;
    height: 100%;
  }

  &__weekday {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 20%;
  }

  &__bar {
    height: 0%;
    position: absolute;
    width: 60%;
    left: 20%;
    bottom: 0;
    background: red;
  }

  &__weekLetters {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 200%);
    width: 100%;
    text-align: center;
    border-radius: 8px;

    &--today {
      color: $secondary-color;
      background: #fff;
    }
  }

  &__weekValue {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }

  &__prizeIcon {
    display: inline-block;
    height: 4vh;
    position: relative;
    top: 1vh;
  }

  &__standingNumber {
    font-size: 2vh!important;
    display: inline-block;
    border: 0.2vh solid $primary-color;
    height: 3.5vh;
    width: 5vh;
    margin-right: 0.5vh;
    border-radius: 4px;
    position: relative;
    top: -0.25vh;
    text-align: center;
    list-style: 1;
  }

  &__rankChange {
    height: 1.8vh;
  }

  &__restaurantName {
    margin-left: 0.6vw;
    position: relative;
    top: -0.5vh;
  }

  &__wasteAmount {
    font-size: 12vh;
    background: #000;
    position: absolute;
    bottom: 20%;
    right: 8%;
    color: #fff;
    padding: 1vh 3vh;
    border-radius: 2vh;
    opacity: 0;

    &--positive {
      background: $primary-color;
      opacity: 1;
    }

    &--negative {
      background: $danger;
      opacity: 1;
    }
  }
}
/* /Magic Forest */

/* Scale view */
.scaleView {
  &__fullScreen {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: -200vh;
  }

  &__foods {
    overflow-y: auto;
    flex: 1;
  }

  &__food {
    background: #000;
    border-radius: 10px;
    margin: 10px 0;
    position: relative;
    padding: 10px 5%;
    p {
      padding: 0;
      margin: 0;
    }
    h2.title {
      margin-bottom: 10px;
    }

    display: block;
    @media screen and (min-width: 450px) {
      display: flex;
    }
  }

  &__foods {
    &--prepared {
      .scaleView__food {
        background: $primary-color;
        h2.title {
          margin-bottom: 10px;
          color: $secondary-color;
        }
      }
    }
    &--waste {
      .scaleView__food {
        background: $secondary-color;
        color: #fff;
      }
    }
  }

  &__left {
    font-weight: 700;
    margin: 0;
    position: relative;
    display: block;
    text-align: center;
    @media screen and (min-width: 450px) {
      min-width: 200px;
      flex: 1;
      display: flex;
      text-align: left;
    }
  }
  &__editServingAmount {
    color: $secondary-color;
    font-size: 30px;
    padding: 30px;
    cursor: pointer;
  }
  &__editWasteAmount {
    color: $primary-color;
    font-size: 30px;
    padding: 30px;
    cursor: pointer;
  }
  &__right {
    position: relative;
    display: block;
    text-align: center;
    @media screen and (min-width: 450px) {
      text-align: right;
      max-width: 180px;
      height: 100%;
    }
    
    button {
      margin: 5px;
      margin-bottom: 0;
      height: 80px;
      width: 160px;
    }
  }

  &__tabs {
    button {
      display: inline-block;
      margin: 10px 0 0 0;
      height: 60px;
      width: 100%;
      @media screen and (min-width: 450px) {
        width: 49%;
        &:first-of-type {
          margin-right: 1%;
        }
        &:last-of-type {
          margin-left: 1%;
        }
      }
    }
  }

  &__value {
    margin-top: 10px;
    border-bottom: 2px solid $secondary-color;
    background: #000;
  }
}
/* /Scale view */
