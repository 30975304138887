@import '../../sass/hukka-bulma-vars.scss';

.date-row-container {
  border: 1px solid $hukka-orange;
  margin: 15px 0;
  box-shadow: 4px 6px 5px 0px #eee;
}

.menu-rows {
  display: flex;
  flex-direction: column;
  margin: auto;
  flex: 1;
}
