$hukka-orange: #ff5722;
$primary-text: #303133;
$text-color: #606266;
$family-sans-serif: urw-din, sans-serif;
$radius: 0px;
$primary: #000c34;
$danger: #c7362a;
$info: $hukka-orange;
$title-color: #000c34;
$title-weight: 900;
$title-line-height: 3;
$primary-color: #2CDBAD;
$secondary-color: #000C33;